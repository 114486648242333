/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Box, Link,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles'

import Button from './Button'
import Logo from './Logo'

import BgImage from '../static/images/bg.png'
import AppImage from '../static/images/app.png'
import ThumbsUpImage from '../static/images/up.png'

const useStyles = makeStyles((theme) => ({
  container: {
    background: `url(${BgImage}) no-repeat`,
    backgroundSize: 'cover',
    height: '100vh',
  },
}))

const ThumbsUp = styled('div', {})(() => ({
  position: 'absolute',
  background: `url(${ThumbsUpImage}) no-repeat center center`,
  backgroundSize: 'contain',
}))

function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <Container
      disableGutters
      maxWidth={false}
      className={classes.container}
    >
      <ThumbsUp style={{
        width: '70px',
        height: '70px',
        top: '30vh',
        left: '5vw',
        transform: 'rotate(20.63deg)',
      }}
      />

      <ThumbsUp style={{
        width: '46px',
        height: '46px',
        top: '65vh',
        left: '5vw',
        transform: 'rotate(-20.41deg)',
      }}
      />

      <ThumbsUp style={{
        width: '46px',
        height: '46px',
        top: '18vh',
        right: '7vw',
        transform: 'rotate(-20.41deg)',
      }}
      />

      <ThumbsUp style={{
        width: '37px',
        height: '37px',
        top: '9vh',
        left: '17vw',
        transform: 'rotate(-30.91deg)',
        opacity: '0.8',
        filter: 'blur(2px)',
      }}
      />

      <ThumbsUp style={{
        width: '56px',
        height: '56px',
        top: '43vh',
        right: '5vw',
        transform: 'rotate(9.09deg)',
        opacity: '0.8',
        filter: 'blur(2px)',
      }}
      />

      <Grid
        container
        justifyContent="space-around"
        spacing={2}
        style={{
          height: '100vh',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            paddingTop: '3rem',
          }}
        >
          <Logo link={link} />
        </Grid>

        <Grid item xs={10} md={7}>
          <img
            src={AppImage}
            alt="Deeply"
            style={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>

        <Grid
          item
          xs={11}
          md={7}
          style={{
            paddingBottom: '3rem',
          }}
        >
          <Button href={link}> TRY FOR FREE </Button>
        </Grid>

      </Grid>
    </Container>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
