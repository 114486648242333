import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LogClick } from '../Log'
import iconImage from '../static/images/logo.png'

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
    '& > a': {
      textDecoration: 'none',
    },
    '& > a > p': {
      fontFamily: '\'Rubik\', sans-serif',
      fontSize: 20,
      fontWeight: '800',
      fontStyle: 'normal',
      lineHeight: '24px',
      color: '#fff',
    },
  },
}))

function Logo({ link }) {
  const classes = useStyles()
  const onClick = () => {
    LogClick()
    // eslint-disable-next-line no-undef
    fbq('track', 'Lead')
  }

  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
    >
      <Grid item>
        <a href={link} onClick={onClick}>
          <img widht="60" height="60" src={iconImage} alt={`${process.env.REACT_APP_TITLE}`} />
        </a>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <a href={link} onClick={onClick}>
          <Typography>Deeply</Typography>
        </a>
      </Grid>
    </Grid>
  )
}

Logo.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Logo
