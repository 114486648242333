/* eslint-disable react/prop-types */
import React from 'react'
import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { LogClick } from '../Log'

// const buttonStyles = makeStyles(() => ({
//   root: {
//     background: '#fff',
//     borderRadius: 9,
//     color: '#1B1F2A',
//     fontWeight: '900',
//     '&:hover': {
//       background: 'linear-gradient(16.59deg, #FFD600 12.6%, #FFB800 88.65%)',
//     },
//   },
//   label: {
//     fontSize: '18px',
//     fontWeight: 'bold',
//     paddingTop: '1rem',
//     paddingBottom: '1rem',
//   },
// }))

const ButtonStyles = styled(MuiButton)(() => ({
  color: '#fff',
  borderRadius: 30,
  fontWeight: '800',
  fontSize: '18px',
  fontFamily: 'Rubik',
  background: 'linear-gradient(180deg, #FFC700 0%, #FF7A00 100%)',
  '&:hover': {
    background: 'linear-gradient(180deg, #FFC700 0%, #FF7A00 100%)',
  },
}))

const Button = ({ ...props }) => {
  const { children } = props

  return (
    <ButtonStyles
      fullWidth
      onClick={() => {
        LogClick()
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead')
      }}
      disableElevation
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </ButtonStyles>
  )
}

export default Button
